export const inputNumberPT = {
  root: { class: ['w-6 flex relative flex-col mt-0.5'] },
  input: {
    root: {
      class: ['ring-0 outline-none bg-transparent text-sm text-inherit'],
    },
  },
};

export const filterByDropdown = {
  root: { style: { width: '120px' } },
  itemgroup: {
    class: [
      'font-bold m-0 py-2 px-2.5 text-secondary-900 text-sm cursor-auto border-b',
    ],
  },
  trigger: {
    class: ['w-6 flex justify-center items-center'],
  },
  panel: {
    class: [
      'rounded-md relative bg-white',
      'me-[-10px] !mt-2.5 border border-secondary-250',
      'before:absolute before:border-transparent before:-top-2.5 before:start-[10px] before:border-l-[10px] before:border-r-[10px] before:border-b-[10px] before:border-b-secondary-250',
      'after:absolute after:border-transparent after:-top-[9px] after:start-[10px] after:border-l-[10px] after:border-r-[10px] after:border-b-[10px] after:border-b-white',
    ],
  },
  item: {
    class: [
      'relative',
      'leading-none text-secondary-900 text-sm',
      'm-0 px-3 py-2',
      'first:mt-0 my-1',
    ],
  },
};

export const feedbackDialogPT = send => ({
  header: { style: { display: send ? 'none' : '', transition: 'all 1s' } },
  footer: { style: { transition: 'all 1s', display: send ? 'none' : '' } },
});

export const feedbackFieldPT = {
  root: 'w-4/5 flex-1 border border-secondary-250 rounded-md focus:outline-none focus:ring-0 resize-none hover:resize transition py-2 px-4 text-sm text-secondary-900',
};

export const historyDropdownPT = disabled => ({
  root: {
    class: [
      'text-sm flex items-center p-0 rounded-md',
      { 'cursor-pointer': !disabled },
    ],
  },
  input: {
    class: ['p-1.5'],
  },
  trigger: {
    class: ['w-6 flex justify-center items-center'],
  },
});

export const lessonDropdownPT = locale => ({
  root: {
    class: [
      'border-0 bg-transparent ',
      'cursor-pointer',
      'select-none',
      'inline-flex',
      'relative',
    ],
  },
  item: {
    dir: locale == 'he' ? 'rtl' : 'ltr',
  },
  trigger: {
    class: ['flex items-center justify-center '],
  },
});
