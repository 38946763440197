<template>
  <TabView v-model:active-index="activeIndex" :lazy="false" :pt="tabViewPT">
    <TabPanel class="flex-1 h-full flex flex-col" header="תרגילים">
      <transition name="slide">
        <div
          v-if="!courseStore.loading"
          :key="activeIndex"
          class="flex-1 h-full flex flex-col">
          <PVInputGroup class="mb-5">
            <PVInputText
              v-model="searchExercise"
              placeholder="חיפוש תרגיל"
              class="outline-none ring-0"
              :disabled="!exercises.length"/>
            <PVInputGroupAddon>
              <Search class="stroke-secondary-600" stroke-width="1.2" />
            </PVInputGroupAddon>
          </PVInputGroup>
          <div
            v-if="filteredExercises.length"
            class="flex-1 h-50 overflow-y-auto">
            <ExerciseCard
              v-for="exercise in filteredExercises"
              :key="exercise.id"
              :exercise="exercise"
              :selected="exercise.id === activeExercise"
              @click="emit('update:activeExercise', exercise.id)"/>
          </div>
          <div v-else class="flex flex-col items-center gap-2 mt-5">
            <img :src="SearchNoResult" alt="no results" />
            <p>לא נמצאו תוצאות חיפוש</p>
          </div>
        </div>
      </transition>
    </TabPanel>
    <TabPanel header="תלמידים">
      <transition name="slide">
        <div :key="activeIndex" class="flex-1 h-full flex flex-col">
          <PVInputGroup class="mb-5">
            <PVInputText
              v-model="searchStudent"
              placeholder="חיפוש תלמיד"
              :disabled="!students.length"/>
            <PVInputGroupAddon>
              <Search class="stroke-secondary-600" stroke-width="1.2" />
            </PVInputGroupAddon>
          </PVInputGroup>
          <div
            v-if="filteredStudents.length"
            class="flex-1 h-50 overflow-y-auto">
            <StudentCard
              v-for="student in filteredStudents"
              :key="student.id"
              :student="student"
              :selected="student.id === activeStudent"
              @click="emit('update:activeStudent', student.id)"/>
          </div>
          <div v-else class="flex flex-col items-center gap-2 mt-5">
            <img :src="SearchNoResult" alt="no results" />
            <p>לא נמצאו תוצאות חיפוש</p>
          </div>
        </div>
      </transition>
    </TabPanel>
  </TabView>
</template>
<script setup lang="ts">
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PVInputText from 'primevue/inputtext';
import PVInputGroup from 'primevue/inputgroup';
import PVInputGroupAddon from 'primevue/inputgroupaddon';
import SearchNoResult from '/@/assets/search-no-result.png';
import { Search } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import StudentCard from '/@/views/components/LessonSubmission/StudentCard.vue';
import ExerciseCard from '/@/views/components/LessonSubmission/ExerciseCard.vue';
import { getExerciseTitle } from '/@/views/components/LessonSubmission/utils';
import { useCourseStore } from '/@/app/store/course';

const courseStore = useCourseStore();
const emit = defineEmits([
  'update:activeStudent',
  'update:activeExercise',
  'update:activePanel',
]);
const props = defineProps({
  exercises: {
    type: Array,
    default: () => [],
  },
  students: {
    type: Array,
    default: () => [],
  },
  activeStudent: {
    type: String,
    default: '',
  },
  activeExercise: {
    type: String,
    default: '',
  },
  activePanel: {
    type: Number,
    default: 0,
  },
});

const activeIndex = useVModel(props, 'activePanel');
const searchExercise = ref('');
const searchStudent = ref('');
const filteredStudents = computed(() => {
  if (!searchStudent.value) return props.students;
  const query = searchStudent.value.toLowerCase();

  return props.students.filter(item =>
    item.fullName.toLowerCase().includes(query),
  );
});

const filteredExercises = computed(() => {
  if (!searchExercise.value) return props.exercises;
  const query = searchExercise.value.toLowerCase();
  return props.exercises.filter(item => {
    const displayName = getExerciseTitle(item);
    return displayName && displayName.toLowerCase().includes(query);
  });
});

const tabViewPT = {
  root: { class: 'px-3 py-5 rounded-lg h-full flex-1' },
  panelContainer: { class: 'px-0 py-5 h-full' },
  inkbar: { class: 'hidden' },
  tabpanel: {
    content: { class: 'h-full' },
    header: () => ({
      class: ['w-full'],
    }),

    headerAction: ({ parent, context }) => ({
      class: [
        'h-full relative',
        'flex items-center justify-center',
        'w-full',
        'py-1.5 px-[1.125rem]',
        '-mb-1',
        'border-b-1',
        'font-simplerLight text-md font-light',
        {
          'border-surface-200': parent.state.d_activeIndex !== context.index,

          'text-secondary-450': parent.state.d_activeIndex !== context.index,

          'border-secondary-900': parent.state.d_activeIndex === context.index,
          'text-secondary-900': parent.state.d_activeIndex === context.index,
        },
        'transition-all duration-200',
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none',
      ],
    }),
  },
};
</script>
