export const blockSettingsType = {
  default: { title: 'רגיל', class: 'text-primary-600 bg-primary-100' },
  bonus: { title: 'בונוס', class: 'text-orange-mango-600 bg-orange-mango-100' },
  reflection: {
    title: 'עוצרים חושבים',
    class: 'text-secondary-800 bg-secondary-100',
  },
};

export const getBlockSettingsType = type => {
  return blockSettingsType[type] || blockSettingsType['default'];
};

export const blockComplexityType = {
  low: 'ידע והבנה',
  high: 'העמקה',
};
export const blockDifficultyType = {
  easy: { title: 'רמה קלה', class: 'text-green-600 bg-aqua-marine-100' },
  medium: { title: 'רמה בינונית', class: 'text-orange-600 bg-orange-100' },
  difficult: { title: 'רמה קשה', class: 'text-red-600 bg-red-100' },
};

export const filterBy = [
  {
    title: 'סנן לפי',
    code: 'filter',
    items: [
      { title: 'כל התרגילים', value: 'all' },
      { title: 'נבדקו', value: 'finished' },
      { title: 'נותרו לבדיקה', value: 'needs_review' },
    ],
  },
];

export const getScoreColor = (value, border, status = null) => {
  if (value == null)
    return `${status == 'awaiting_review' ? 'border-primary' : 'text-secondary-300'}`;
  if (value < 50) {
    return `text-red-500 bg-red-50 ${border ? 'border-red-500' : ''}`;
  }
  if (value >= 50 && value < 80) {
    return `text-orange-mango-500 bg-orange-mango-50 ${border ? 'border-orange-mango-500' : ''}`;
  }
  if (value >= 80) {
    return `text-aqua-marine-700 bg-aqua-marine-50 ${border ? 'border-aqua-marine-500' : ''}`;
  }
  return `text-secondary-600 bg-white ${border ? 'border-secondary-300' : ''}`;
};

export const numbersMapping = {
  1: 'ראשונה',
  2: 'שנייה',
  3: 'שלישית',
  4: 'רביעית',
  5: 'חמישית',
  6: 'שישית',
  7: 'שביעית',
  8: 'שמינית',
  9: 'תשיעית',
  10: 'עשירית',
};

const blockNames = {
  rich_text: 'טקסט',
  free_text: 'פסקה',
  audio_listener: 'שמע',
  embed: 'הטמעה',
  embed_website: 'הטמעה',
  image: 'תמונה',
  disclaimer: 'הערה',
  file_upload: 'העלאת קובץ',
  custom_video: 'סרטון',
  audio_player: 'הקלטה',
  textarea: 'תשובה קצרה',
  highlight: 'סימון',
  pairing: 'התאמה',
  fill_blanks: 'השלמת מילים',
  word_cloud: 'ענן מילים',
  match: 'מיון',
  table: 'טבלה',
  choice_range: 'טווח',
  forum: 'פורום',
  sticky_notes: 'לוח שיתופי',
  calendar: 'לוח שנה',
  smart_goal: 'טבלת סמארט',
  project_plan: 'טבלת יעדים',
  gantt: 'גאנט',
  choice_radio: 'בחירה',
  choice_checkbox: 'בחירה',
  choice_post: 'בחירה',
  choice: 'בחירה',
  heading: 'כּותרת',
  poll: 'סקר',
  sort: 'סידור',
  decision_making_game: 'משחק קבלת החלטות',
  draggable_table: 'מיון טבלאות',
  calendar_drag: 'מערכת שעות',
  priority_quadrant: 'דחוף חשוב',
  collaborative_board: 'לוח שיתופי',
  plan_control: 'תוכנית פעולה',
};

export const getBlockLabel = element => {
  const type = element?.content?.type;
  return blockNames[`${element?.name}_${type}`] ?? blockNames[element?.name];
};

export const getExerciseTitle = exercise => {
  if (exercise.questions.length > 1) {
    return exercise.title;
  }
  return getBlockLabel(exercise.questions[0]);
};
