<template>
  <div class="flex flex-col gap-2">
    <div
      class="rounded-md border border-secondary-250 p-5 bg-white flex flex-col gap-2">
      <div class="flex items-center justify-between">
        <div
          v-if="reviewPer == 'student'"
          class="flex items-center justify-between">
          <div
            v-if="exercise.exercise?.questions?.length"
            class="flex gap-2 h-full">
            <span
              :class="`text-sm text-orange-mango-600 bg-orange-mango-100 px-2 py-1 rounded-md ${currentExerciseType?.class}`">
              {{ currentExerciseType?.title }}
            </span>
            <span
              :class="`text-sm px-2 py-1 rounded-md ${currentExerciseDifficulty?.class}`">
              {{ currentExerciseDifficulty?.title }}
            </span>
            <span
              class="flex gap-2 items-center text-sm text-secondary-900 py-1">
              {{ blockComplexityType[exercise.exercise?.complexity ?? 'low'] }}
            </span>
            <span class="flex items-center text-sm">
              {{ exercise.exercise.weight }}%
            </span>
            <span
              v-if="!studentCollaborationRecord?.length || changesRequested"
              class="text-sm flex items-center gap-1.5">
              /
              <span
                v-if="!studentCollaborationRecord?.length"
                class="flex items-center font-bold text-red-600 text-sm">
                לא הוגש
              </span>
              <span
                v-else
                class="flex items-center font-bold text-red-600 text-sm">
                נשלח לתיקון
              </span>
            </span>
          </div>
        </div>
        <div v-else>
          <div class="flex gap-2 items-center h-full">
            <img
              :src="
                showStudentsNames && exercise.student?.avatar
                  ? exercise.student?.avatar
                  : defaultImage
              "
              alt="avatar"
              class="rounded-full w-6 h-6"/>
            <p class="font-bold text-secondary-900">
              {{ showStudentsNames ? exercise.student?.fullName : 'אנונימי' }}
              <span
                v-if="!studentCollaborationRecord?.length || changesRequested"
                class="font-bold">
                /
                <span
                  v-if="!studentCollaborationRecord?.length"
                  class="font-bold text-red-600">
                  לא הוגש
                </span>
                <span v-else class="font-bold text-red-600">נשלח לתיקון</span>
              </span>
            </p>
          </div>
        </div>
        <div>
          <PVDropdown
            v-if="!exerciseSettings?.submitByGroup"
            v-model="selectedSubmission"
            :options="historyOptions"
            :disabled="!studentSubmission?.history?.length"
            option-label="title"
            :pt="dropdownPT">
            <template #value="{ value, placeholder }">
              <div v-if="selectedSubmission">
                {{ selectedSubmission.title }}
              </div>
              <div v-else class="flex justify-center items-center gap-2">
                <File />
                <span class="text-sm text-secondary-900">
                  {{
                    studentSubmission?.history?.length +
                      (studentSubmission?.records?.length ? 1 : 0) || 0
                  }}
                  הגשות
                </span>
              </div>
            </template>
            <template #dropdownicon>
              <ChevronDown
                v-if="!selectedSubmission"
                class="stroke-secondary-900"
                :size="16"/>
              <X
                v-else
                size="16"
                class="cursor-pointer"
                @click="clearSelectedSubmission"/>
            </template>
          </PVDropdown>
        </div>
      </div>
      <BlockPreview
        v-for="question in props.exercise.exercise.questions"
        :key="`s-${exercise.student.id}-${question.id}`"
        :block="question"/>
      <div class="flex items-center justify-between mt-2">
        <div v-show="!selectedSubmission" class="flex gap-1.5">
          <PVButton
            v-if="showScoreField && !changesRequested"
            severity="neutral"
            class="bg-white text-sm flex gap-1.5 !px-3 !py-1.5 me-0"
            @click="isOpenFeedbackModal = true">
            <ChartStart :class="{ 'opacity-60': !studentSubmission }" />
            משוב / תיקון
          </PVButton>
          <PVButton
            v-if="false"
            severity="neutral"
            class="bg-white text-sm flex gap-1.5 !px-3 !py-1.5 me-0 !border-0 pointer-events-none opacity-50">
            <ChatMessage />
            ערעור
          </PVButton>
        </div>
        <div class="mr-auto flex gap-2">
          <Transition name="fade">
            <div
              v-if="messageVisible"
              class="flex items-center justify-center transition-all">
              <Info class="fill-orange-500 stroke-white" />
              <p class="text-sm text-orange-500">שים לב! הוספת ציון בונוס</p>
            </div>
          </Transition>
          <div
            v-if="showScoreField"
            :class="[
              `flex gap-2 items-center justify-center px-2.5 py-1 border rounded-md ${scoreColor}`,
              {
                '!border-primary !bg-white !text-secondary-900': scoreFocus,
              },
            ]">
            <span class="text-sm !leading-0 text-inherit">ציון:</span>
            <PVInputNumber
              v-model="score"
              :disabled="!!selectedSubmission"
              :min="0"
              :max="120"
              placeholder="--"
              :pt="inputNumberPT"
              @focus="scoreFocus = true"
              @blur="onBlur"/>
          </div>
        </div>
      </div>
    </div>
    <FeedbackModal
      v-if="isOpenFeedbackModal"
      :open="isOpenFeedbackModal"
      :exercise="exercise.exercise"
      :student="exercise.student.id"
      :submission="studentSubmission"
      @close="isOpenFeedbackModal = false"/>
  </div>
</template>

<script setup lang="ts">
import { renderBlock } from '@';
import { computed, h, nextTick, onUnmounted, ref, watch } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
import PVInputNumber from 'primevue/inputnumber';
import { useStateMapper } from '/@/app/composables/useStateMapper';
import PVButton from 'primevue/button';
import ChartStart from '/@/assets/chat-start.svg';
import ChatMessage from '/@/assets/chat-message.svg';
import FeedbackModal from '/@/views/components/LessonSubmission/FeedbackModal.vue';
import { ChevronDown, Info, X } from 'lucide-vue-next';
import {
  blockComplexityType,
  blockDifficultyType,
  numbersMapping,
  getScoreColor,
  getBlockSettingsType,
} from '/@/views/components/LessonSubmission/utils';

const { toState } = useStateMapper();
import PVDropdown from 'primevue/dropdown';
import File from '/@/assets/file.svg';
import { useRouteQuery } from '@vueuse/router/index';
import { setSubmissionScore } from '/@/app/services/students';
import { useRoute } from 'vue-router';
import { useCourseStore } from '/@/app/store/course';
import {
  historyDropdownPT,
  inputNumberPT,
} from '/@/views/components/LessonSubmission/styles';
import { isEmpty } from 'lodash';

const defaultImage =
  'https://res.cloudinary.com/dcodkxpej/image/upload/v1725192676/default-avatar-image_adpnhe.png';

const studentsStore = useStudentsStore();
const courseStore = useCourseStore();
const route = useRoute();
const submissions = computed(() => studentsStore.submissions);
const dropdownPT = computed(() =>
  historyDropdownPT(!studentSubmission.value?.history?.length),
);

const reviewPer = useRouteQuery<any>('review_per', 'exercise');
const props = defineProps({
  exercise: {
    type: Object,
    default: () => ({}),
  },
  showStudentsNames: {
    type: Boolean,
    default: true,
  },
});

const scoreFocus = ref(false);

const exerciseSettings = computed(
  () => props.exercise?.exercise?.questions?.[0]?.settings,
);
const currentExerciseSettings = computed(
  () => props.exercise?.exercise?.questions[0]?.settings,
);
const studentCollaborationRecord = computed(() =>
  studentSubmission.value?.records.filter(
    record => !record.userId || record.userId === props.exercise.student.id,
  ),
);
const currentExerciseType = computed(() =>
  getBlockSettingsType(currentExerciseSettings.value?.type),
);
const currentExerciseDifficulty = computed(
  () => blockDifficultyType[props.exercise?.exercise?.difficulty ?? 'easy'],
);
const MAX_SCORE = 120;

const isOpenFeedbackModal = ref(false);
const messageVisible = ref(false);
let timeoutId;

const onBlur = () => {
  nextTick(() => {
    scoreFocus.value = false;

    if (score.value == null || score.value == studentSubmission.value.score) {
      score.value = studentSubmission.value.score ?? null;
      return;
    }

    if (score.value > 100) {
      messageVisible.value = true;
    }

    if (score.value > MAX_SCORE) {
      score.value = MAX_SCORE;
    }

    const { onSuccess } = setSubmissionScore(studentSubmission.value?.id, {
      score: score.value,
    });
    onSuccess(() => {
      studentsStore.fetchEnrollments({
        group: route.params.group,
        course: route.params.course,
      });
      studentsStore.fetchSubmissions(route.params.group, route.params.course);
      if (score.value <= 100) return;

      timeoutId = setTimeout(() => {
        messageVisible.value = false;
      }, 2000);
    });
  });
};

onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

const BlockPreview = props => {
  const submissions = isEmpty(studentSubmission.value)
    ? []
    : studentSubmission.value.records;
  const state =
    getStudentSubmissionAnswer(props.block) ||
    toState(props.block, submissions);
  const blockPreview = renderBlock({
    editable: false,
    block: props.block,
    state,
  });

  return h(
    'div',
    {
      dir: courseStore.course.locale !== 'he' ? 'ltr' : 'rtl',
      class:
        'block flex flex-col items-start justify-start space-y-3 text-start py-5 h-full w-full bg-secondary-75 w-full max-w-full overflow-x-auto',
    },
    blockPreview,
  );
};
BlockPreview.props = {
  block: Object,
};
const selectedSubmission = ref();
const studentSubmission = computed(() =>
  submissions.value.find(
    s =>
      s.participants.includes(props.exercise.student.id) &&
      s.exercise == props.exercise.exercise.id,
  ),
);
const score = ref();

const changesRequested = computed(() =>
  ['changes_requested', 'pending_changes'].includes(
    studentSubmission.value?.status,
  ),
);

const showScoreField = computed(
  () =>
    studentSubmission.value &&
    !['pending', 'pending_changes'].includes(studentSubmission.value.status) &&
    studentSubmission.value.type !== 'collaboration' &&
    !props.exercise.exercise.questions.some(
      q =>
        q.settings?.submitByGroup == true || q.name == 'decision_making_game',
    ),
);

watch(
  () => [studentSubmission.value],
  () =>
    (score.value = ['finished', 'changes_requested'].includes(
      studentSubmission.value?.status,
    )
      ? studentSubmission.value?.score
      : null),
  { immediate: true },
);

watch(
  () => [selectedSubmission.value],
  () => {
    score.value = selectedSubmission.value
      ? selectedSubmission.value.score
      : studentSubmission.value?.score;
  },
);

const scoreColor = computed(() =>
  getScoreColor(score.value, true, studentSubmission.value?.status),
);

const historyOptions = computed(() =>
  studentSubmission.value?.history.map((h, index) => ({
    title: `${index + 1}. הגשה ${numbersMapping[index + 1] ?? ''}`,
    code: index,
    score: h.score,
  })),
);
const clearSelectedSubmission = e => {
  e.preventDefault();
  e.stopPropagation();
  selectedSubmission.value = null;
};
const getStudentSubmissionAnswer = block => {
  const record = selectedSubmission.value
    ? studentSubmission.value['history'][selectedSubmission.value.code]
    : studentSubmission.value;
  const submission = record?.records?.find(
    record =>
      record.question === block.id &&
      (!record.userId || record.userId === props.exercise.student.id),
  )?.answer;

  return submission;
};
</script>

<style>
.am-block {
  @apply scale-90 -ms-3 pointer-events-none;
}

.am-block:has(.fc-event) {
  @apply scale-95 pointer-events-auto;
}

.am-block .form-item > h5,
.am-block .form-item > p {
  @apply text-secondary-900 text-start;
}

.am-block .bank-button,
.am-block .bank-button ~ .sticky {
  @apply hidden;
}

.play-button,
.file-link,
.tag-box,
.progress-tab,
.drag-box {
  @apply pointer-events-auto;
}
.tag-input,
.fc-event-draggable,
.drag-box .item {
  @apply pointer-events-none;
}
.tag-box input {
  @apply hidden;
}
.remove-file {
  @apply hidden pointer-events-none;
}

.block[dir='ltr'] .editor-content p.is-editor-empty:first-child::before {
  float: left;
}
.flex.gap-1.border-e.border-secondary-300.px-1:nth-child(3) {
  @apply hidden;
}
</style>
