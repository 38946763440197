<template>
  <PVDialog
    v-model:visible="isOpen"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    class="transition-all duration-500"
    :style="{ width: dialogWidth }"
    :pt="feedbackDialogPT(send)"
    @hide="emit('close')">
    <template #header>
      <p class="text-secondary-800 font-semibold">שליחת משוב / תיקון</p>
    </template>
    <Transition name="slide-fade">
      <div v-if="!send" class="flex flex-col gap-3">
        <p class="text-secondary-900 text-sm">משוב</p>

        <PVTextArea
          v-model="feedback"
          rows="2"
          placeholder=""
          :pt="feedbackFieldPT"
          auto-resize/>

        <div class="flex gap-2 align-items-center">
          <PVCheckbox
            v-model="changesRequested"
            input-id="fix"
            name="changesRequested"
            :value="true"/>
          <label for="fix" class="text-secondary-900 text-sm">
            שליחת התרגיל לתיקון
          </label>
        </div>
      </div>

      <div v-else class="flex flex-col items-center gap-3">
        <div class="w-full flex justify-end">
          <X
            size="16"
            class="stroke-secondary-800 cursor-pointer"
            @click="emit('close')"/>
        </div>
        <img :src="HandFeedback" width="100" height="100" alt="" />
        <span class="font-bold text-secondary-900 w-50 text-center">
          הפידבק שכתבת נשלח בהצלחה לתלמיד
        </span>
      </div>
    </Transition>

    <template #footer>
      <div v-if="!send" class="flex gap-4 justify-end">
        <PVButton
          label=" שליחה"
          :disabled="loading || !feedback?.trim().length"
          :loading="loading"
          @click="sendFeedback"/>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { X } from 'lucide-vue-next';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import PVCheckbox from 'primevue/checkbox';
import PVTextArea from 'primevue/textarea';

import { ref } from 'vue';
import { reviewSubmission } from '/@/app/services/students';
import { useRoute } from 'vue-router';

import HandFeedback from '/@/assets/hand-feedback.png';
import {
  feedbackDialogPT,
  feedbackFieldPT,
} from '/@/views/components/LessonSubmission/styles';
import { useStudentsStore } from '/@/app/store/students';

const route = useRoute();
const studentsStore = useStudentsStore();

const emit = defineEmits('close');
const isOpen = defineModel<boolean>('open');
const dialogWidth = ref('500px');
const changesRequested = ref(false);

const send = ref(false);
const loading = ref(false);
const props = defineProps({
  exercise: {
    type: Object,
    default: () => ({}),
  },
  student: {
    type: String,
    default: '',
  },
  submission: {
    type: Object,
    default: () => ({}),
  },
});
const feedback = ref(props.submission?.review_notes);

const sendFeedback = () => {
  loading.value = true;
  let data: any = {
    title: 'תגובה חדשה ממורה',
    notes: feedback.value,
    metadata: {
      course: route.params.course,
      lesson: route.params.lesson,
      exercise: props.exercise.id,
      page: props.exercise.questions[0].page,
    },
  };

  if (changesRequested.value) {
    data = {
      ...data,
      changes_requested: true,
      metadata: {
        ...data.metadata,
        changes_requested: true,
      },
    };
  }

  const { onSuccess } = reviewSubmission(props.submission?.id, data);
  onSuccess(async () => {
    studentsStore.fetchSubmissions(route.params.group, route.params.course);
    loading.value = false;
    send.value = true;
    dialogWidth.value = '300px';
  });
};
</script>
